import React from 'react';
import Page from '../../../components/Page/Page';
import ButtonHorizontal from '../../../components/Buttons/ButtonHorizontal/ButtonHorizontal';
import SubjectChange from '../../../components/SubjectChange/SubjectChange';
import { authActionCreators } from '../../../redux/slices/auth/actionCreators';
import { useTypedDispatch } from '../../../redux/hooks';
import { SplashScreen } from '@capacitor/splash-screen';
import { Link } from 'react-router-dom';
import { isPlatform } from '@ionic/core';

const UserSettingsPage: React.FC = () => {
  const dispatch = useTypedDispatch();

  return (
    <Page headerProps={{ title: 'Settings', hideBackButton: true }}>
      <SubjectChange />

      {!isPlatform('capacitor') ? (
        <Link to={{ pathname: '/', state: { allowVisitLandning: true } }}>
          <ButtonHorizontal text="About Eventmaker" />
        </Link>
      ) : (
        <ButtonHorizontal text="About " routerLink="/settings/about" />
      )}

      <ButtonHorizontal text="Manage account" routerLink="/settings/account" />

      <ButtonHorizontal
        text="Register your business"
        routerLink="/settings/register-business"
      />
      <ButtonHorizontal text="Contact support" routerLink="/settings/support" />
      <ButtonHorizontal
        text="Logout"
        onClick={async () => {
          dispatch(authActionCreators.logout());
          await SplashScreen.show({
            showDuration: 1000,
            autoHide: true,
          });
        }}
      />
    </Page>
  );
};

export default UserSettingsPage;
