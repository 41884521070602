import React from 'react';
import clsx from 'clsx';
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import s from './ConfirmationModal.module.scss';
import sg from '../../styles/global.module.scss';
import { IS_INSTAGRAM } from '../../utils/browser';
import Button from '../Buttons/Button/Button';

type Props = {
  isOpen: boolean;
  close: () => void;
  onConfirm: () => void;
  headerText?: boolean;
  contentText: string;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  isLoading?: boolean;
};

export const ConfirmationModal: React.FC<Props> = ({
  isOpen,
  close,
  onConfirm,
  headerText = 'Are you sure?',
  contentText,
  confirmButtonTitle = 'Continue',
  cancelButtonTitle = 'Cancel',
  isLoading,
}) => {
  return (
    <IonModal
      animated={!IS_INSTAGRAM}
      backdropDismiss
      keepContentsMounted
      handle={false}
      isOpen={isOpen}
      onWillDismiss={close}
      className={clsx(s.IonModal)}
    >
      <IonHeader>
        <IonToolbar mode="ios">
          <p className={clsx(s.Text__Header)}>{headerText}</p>
          <IonButtons slot="end">
            <IonButton onClick={close}>
              <IonIcon icon={closeOutline} className={clsx(s.Close__button)} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div style={{ padding: '10px' }} className={clsx(sg.Stack_Vertical)}>
        <p className={clsx(s.Text__Content)} style={{ padding: '20px 0' }}>
          {contentText}
        </p>

        <div className={clsx(sg.Stack_Horizontal)}>
          <Button
            width="90%"
            text={cancelButtonTitle}
            color="primary"
            onClick={close}
          />
          <Button
            width="90%"
            text={confirmButtonTitle}
            color="secondary"
            fill="solid"
            onClick={onConfirm}
            loading={isLoading}
          />
        </div>
      </div>
    </IonModal>
  );
};
