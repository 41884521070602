import clsx from 'clsx';
import sg from '../../styles/global.module.scss';
import s from './SubjectChange.module.scss';
import SubjectMini from '../SubjectMini/SubjectMini';
import { useMemo, useRef, useState } from 'react';
import { IonCard, IonPopover, useIonViewDidEnter } from '@ionic/react';
import { useTypedSelector } from '../../redux/hooks';
import { buildUrlsFromUploadedFile } from '../Inputs/InputFile/oneFile/helpers';
import { useHistory } from 'react-router';
import { subjectsSlice } from '../../redux/slices/subjects/slice';
import { useTypedDispatch } from '../../redux/hooks';
import { globalSlice } from '../../redux/slices/global/slice';
import ButtonLabel from '../Buttons/ButtonLabel/ButtonLabel';
import { chevronForwardOutline } from 'ionicons/icons';
import { Business } from '../../models/business';
import { authActionCreators } from '../../redux/slices/auth/actionCreators';

interface SubjectGenericFullData {
  title: string;
  description: string;
  previewSrc: string | null | undefined;
  contentType: 'user' | 'business' | 'organizer';
  objectId: string;
  isActive?: boolean;
  businessStatus?: Business['status'];
}

const SubjectChange: React.FC = () => {
  const user = useTypedSelector(s => s.auth.user)!;
  const businessSet = useTypedSelector(s => s.subjects.businessSet)!;
  const organizerSet = useTypedSelector(s => s.subjects.organizerSet)!;
  const activeSubject = useTypedSelector(s => s.subjects.activeSubject)!;

  const canSwitch = businessSet.length || organizerSet.length;

  const dispatch = useTypedDispatch();

  const history = useHistory();

  const [isPopoverOpened, setIsPopoverOpened] = useState(false);

  const allSubjectsFullData: SubjectGenericFullData[] = useMemo(() => {
    let result: SubjectGenericFullData[] = [];

    const previewSrc = buildUrlsFromUploadedFile(user.avatarFile).thumbnail.low;

    result.push({
      title: user.email,
      description: canSwitch ? 'User dashboard' : 'Your account',
      previewSrc,
      contentType: 'user',
      objectId: user.id,
    });

    for (const business of businessSet) {
      const previewSrc = buildUrlsFromUploadedFile(business.uploadedFiles[0])
        .thumbnail.low;

      result.push({
        title: business.name,
        description: business.category
          ? business.category.name
          : business.categoryCustom!,
        previewSrc,
        contentType: 'business',
        objectId: business.id,
        businessStatus: business.status,
      });
    }

    for (const organizer of organizerSet) {
      // ...
    }

    return result;
  }, [user, businessSet, organizerSet, canSwitch]);

  const activeSubjectFullData = useMemo(() => {
    return allSubjectsFullData.find(subject => {
      if (activeSubject.contentType === 'user') {
        return subject.contentType === 'user';
      } else {
        return (
          subject.contentType === activeSubject.contentType &&
          subject.objectId === activeSubject.objectId
        );
      }
    });
  }, [allSubjectsFullData, activeSubject]);

  const redirectToChoosedSubject = ({
    contentType,
    objectId,
  }: SubjectGenericFullData) => {
    setIsPopoverOpened(false);

    if (
      contentType === activeSubjectFullData?.contentType &&
      objectId === activeSubjectFullData?.objectId
    ) {
      return;
    }

    let redirectUrl: string;
    if (contentType === 'user') {
      redirectUrl = '/settings';
    } else {
      const prefix = contentType === 'business' ? 'business' : 'organizer';
      redirectUrl = `/${prefix}/${objectId}/settings`;
    }

    dispatch(globalSlice.actions.setModalHiderVisibility(true));

    setTimeout(() => {
      dispatch(
        subjectsSlice.actions.setActiveSubject({
          contentType,
          objectId,
        })
      );

      history.push(redirectUrl);
    }, 400);
  };

  const isBusinessPending = activeSubjectFullData?.businessStatus === 'pending';

  const lastFetchRef = useRef<number>();
  useIonViewDidEnter(() => {
    if (!isBusinessPending) return;

    const now = new Date().getTime();
    const ONE_MINUTE = 60 * 1000;

    // Recursively check if status been updated
    if (!lastFetchRef.current || now - lastFetchRef.current > ONE_MINUTE) {
      lastFetchRef.current = now;
      dispatch(authActionCreators.fetchAllPrivateData({ nextAuthPhase: null }));
    }
  }, [isBusinessPending, dispatch]);

  return (
    <>
      <div
        className={clsx(sg.Stack_Horizontal, sg.Gap_10, s.Subject__container)}
        style={{ cursor: canSwitch ? 'pointer' : 'unset' }}
      >
        <div id="hided-fake-trigger-used-for-alignment" />

        <SubjectMini
          softDisabled={!canSwitch}
          suffixIcon={
            canSwitch ? (
              <ButtonLabel label="SWITCH" iconSrc={chevronForwardOutline} />
            ) : null
          }
          title={activeSubjectFullData!.title}
          description={activeSubjectFullData!.description}
          image={activeSubjectFullData!.previewSrc}
          isActive={true}
          onClick={() => setIsPopoverOpened(true)}
        />

        <IonPopover
          className={clsx(s.IonPopover)}
          reference="trigger"
          trigger="hided-fake-trigger-used-for-alignment"
          mode="ios"
          isOpen={isPopoverOpened}
          onDidDismiss={() => {
            setIsPopoverOpened(false);
          }}
        >
          <div className={clsx(sg.Stack_Vertical, sg.Gap_5)}>
            {allSubjectsFullData.map((subjectFullData, i) => (
              <div
                key={i}
                onClick={() => redirectToChoosedSubject(subjectFullData)}
              >
                <SubjectMini
                  prefixIcon
                  title={subjectFullData.title}
                  description={subjectFullData.description}
                  image={subjectFullData.previewSrc}
                  isActive={
                    subjectFullData.contentType ===
                      activeSubjectFullData!.contentType &&
                    subjectFullData.objectId === activeSubjectFullData!.objectId
                      ? true
                      : false
                  }
                />
                <div className={clsx(s.Subject_Choice__hr)} />
              </div>
            ))}
          </div>
        </IonPopover>
      </div>

      {activeSubjectFullData?.contentType === 'business' && (
        <IonCard
          style={{
            margin: '10px 0',
            border: 'solid 1px var(--color-main-medium)',
            opacity: 0.8,
          }}
        >
          <p style={{ lineHeight: 1.5 }}>
            {isBusinessPending
              ? `Offerings from "${activeSubjectFullData.title}" are not
            visible to clients because we haven't verified your business yet.`
              : 'Switch to "User Dashboard" if you want to see how your offerings looks for clients.'}
          </p>
        </IonCard>
      )}
    </>
  );
};

export default SubjectChange;
