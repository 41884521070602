import React, { useEffect, useMemo } from 'react';
import { Route } from 'react-router-dom';
import {
  IonIcon,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { useLocation, useRouteMatch } from 'react-router';
import {
  appsOutline,
  helpOutline,
  notificationsOutline,
  settingsOutline,
} from 'ionicons/icons';
import { isPlatform } from '@ionic/core';
import BusinessProductsRoutes from '../pages/business/products/BusinessProductsRoutes';
import NotificationsPage from '../pages/NotificationsPage';
import BusinessSettingsRoutes from '../pages/business/settings/BusinessSettingsRoutes';
import { AppMenu } from './AppMenu';
import { useTypedDispatch } from '../redux/hooks';
import { SubjectGeneric, subjectsSlice } from '../redux/slices/subjects/slice';
import clsx from 'clsx';
import { isBusinessFormPage } from './helpers';
import { AppConfigType } from './types';
import EventsPage from '../pages/EventsPage';
import { AppTabLogo } from './AppTabLogo';
import BusinessFaqPage from '../pages/BusinessFaqPage';
import { IS_IOS_NATIVE, IS_IOS_PWA } from '../utils/browser';

const AppBusiness: React.FC = () => {
  const match = useRouteMatch<{ businessId: string }>();

  const location = useLocation();
  const pathName = location.pathname;

  const dispatch = useTypedDispatch();
  useEffect(() => {
    const activeSubject: SubjectGeneric = {
      contentType: 'business',
      objectId: match.params.businessId,
    };
    dispatch(subjectsSlice.actions.setActiveSubject(activeSubject));
  }, [dispatch, match.params.businessId]);

  console.log({ match });

  const config: AppConfigType = useMemo(() => {
    return [
      {
        link: `${match.url}/products`,
        name: 'My Services',
        icon: appsOutline,
      },
      {
        link: `${match.url}/faq`,
        name: 'FAQ',
        icon: helpOutline,
      },
      {
        link: `${match.url}/events`,
        name: 'My Events',
        icon: 'custom',
      },
      {
        link: `${match.url}/notifications`,
        name: 'Notifications',
        icon: notificationsOutline,
      },
      {
        link: `${match.url}/settings`,
        name: 'Settings',
        icon: settingsOutline,
      },
    ].map(cfg => ({ ...cfg, isActive: pathName.startsWith(cfg.link) }));
  }, [match.url, pathName]);

  const isFormPage = useMemo(() => isBusinessFormPage(pathName), [pathName]);
  const isCentralTabActive = pathName.startsWith(config[2].link);

  return isPlatform('desktop') ? (
    <IonSplitPane contentId="main" when="xs">
      <AppMenu config={config} isFormPage={isFormPage} />

      <IonRouterOutlet id="main">
        <Route path={`${match.path}/products`}>
          <BusinessProductsRoutes />
        </Route>

        <Route path={`${match.path}/faq`}>
          <BusinessFaqPage />
        </Route>

        <Route path={`${match.path}/events`}>
          <EventsPage isBusiness />
        </Route>

        <Route path={`${match.path}/notifications`}>
          <NotificationsPage isBusiness />
        </Route>

        <Route path={`${match.path}/settings`}>
          <BusinessSettingsRoutes />
        </Route>
      </IonRouterOutlet>
    </IonSplitPane>
  ) : (
    <IonTabs>
      <IonRouterOutlet>
        <Route path={`${match.path}/products`}>
          <BusinessProductsRoutes />
        </Route>

        <Route path={`${match.path}/faq`}>
          <BusinessFaqPage />
        </Route>

        <Route path={`${match.path}/events`}>
          <EventsPage isBusiness />
        </Route>

        <Route path={`${match.path}/notifications`}>
          <NotificationsPage isBusiness />
        </Route>

        <Route path={`${match.path}/settings`}>
          <BusinessSettingsRoutes />
        </Route>
      </IonRouterOutlet>

      <IonTabBar
        id="ionTabBar"
        slot="bottom"
        mode="ios"
        className={clsx(
          (IS_IOS_NATIVE || IS_IOS_PWA) && 'fillColorToBottom',
          'disableIosSafariSwipeBlocker'
        )}
        style={isFormPage ? { display: 'none' } : undefined}
      >
        {config.map(cfg =>
          cfg.icon !== 'custom' ? (
            <IonTabButton
              key={cfg.link}
              tab={cfg.name}
              href={cfg.link}
              className="disableIosSafariSwipeBlocker"
            >
              <IonIcon icon={cfg.icon} />
            </IonTabButton>
          ) : (
            <IonTabButton
              key={cfg.link}
              tab={cfg.name}
              href={cfg.link}
              className="centralTab"
            >
              <AppTabLogo isTabActive={isCentralTabActive} />
            </IonTabButton>
          )
        )}
      </IonTabBar>
    </IonTabs>
  );
};

export default AppBusiness;
