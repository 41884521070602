import React, { useCallback } from 'react';
import clsx from 'clsx';
import Centralizer from '../Centralizer/Centralizer';
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import { ContactInfoContextType } from './context';
import { closeOutline, copyOutline } from 'ionicons/icons';
import s from './ContactInfoModal.module.scss';
import sg from '../../styles/global.module.scss';
import { useTypedDispatch, useTypedSelector } from '../../redux/hooks';
import { globalActionCreators } from '../../redux/slices/global/actionCreator';
import { ToastPriorityEnum } from '../../utils/enums';
import ButtonMicroWrapper from '../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';
import { IS_INSTAGRAM } from '../../utils/browser';
import { TextUsButton } from '../../pages/landing/Components/Sections/Form/Subforms/TextUsButton';
import { useGetProductExclusiveQuery } from '../../redux/api';
import Spinner from '../Spinner/Spinner';

export const ExclusiveAccessModal: React.FC<{
  contactInfoContextData: ContactInfoContextType;
}> = ({ contactInfoContextData }) => {
  const info = contactInfoContextData?.info;

  const dispatch = useTypedDispatch();

  const user = useTypedSelector(s => s.auth.user);

  const { data, isLoading } = useGetProductExclusiveQuery(
    { id: info?.product?.id! },
    { skip: !info?.product?.id || !user?.hasExclusiveAccess }
  );

  const content = `Hello, we have a client who is interested in your services for their event. Can we discuss availability and prices?\n
https://eventmaker.app/catalog/${info?.product?.id || '-'}\n
--------\n
EventMaker - online platform connecting Bali event planners.
About: https://eventmaker.app/
`;

  const link = `https://wa.me/${data?.phoneNumber}?text=${encodeURI(content)}`;

  const onMessageCopy = useCallback(() => {
    navigator.clipboard.writeText(content || '-');
    dispatch(
      globalActionCreators.setToastObject({
        message: 'Text message copied to clipboard',
        color: 'success',
        priority: ToastPriorityEnum.HIGH,
      })
    );
  }, [dispatch, content]);

  const close = () => contactInfoContextData?.setInfo(undefined);

  return (
    <IonModal
      animated={!IS_INSTAGRAM}
      backdropDismiss
      keepContentsMounted
      handle={false}
      isOpen={
        !!contactInfoContextData?.info &&
        contactInfoContextData.info.isExclusiveAccessModal
      }
      onWillDismiss={close}
      className={clsx(s.IonModal)}
    >
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="end">
            <IonButton onClick={close}>
              <IonIcon icon={closeOutline} className={clsx(s.Close__button)} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {isLoading ? (
        <Spinner />
      ) : (
        <div style={{ padding: '10px' }} className={clsx(sg.Stack_Vertical)}>
          <p className={clsx(s.Text__Content)} style={{ padding: '10px 0' }}>
            Send following message to "{data?.name}"
          </p>

          <Centralizer>
            <div
              className={clsx(
                sg.Stack_Horizontal,
                sg.Justify_Center,
                sg.Gap_10,
                s.Text__dotted_border
              )}
              style={{ padding: '10px', marginTop: '10px' }}
            >
              <p className={clsx(s.Text__Content)}>{content}</p>

              <ButtonMicroWrapper onClick={onMessageCopy}>
                <IonIcon src={copyOutline} className={clsx(s.IonIcon__copy)} />
              </ButtonMicroWrapper>
            </div>
          </Centralizer>

          <Centralizer>
            <div>
              <TextUsButton
                vendorNumber={data?.phoneNumber}
                style={{ marginTop: '25px' }}
                alignToCenter
                onClick={() => {
                  close();
                  window.open(link);
                }}
              />
            </div>
          </Centralizer>
        </div>
      )}
    </IonModal>
  );
};
