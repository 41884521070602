import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { api } from './api';
import { rtkQueryErrorLogger } from './middlewares';
import { authSlice } from './slices/auth/slice';
import { choicesSlice } from './slices/choices/slice';
import { globalSlice } from './slices/global/slice';
import { subjectsSlice } from './slices/subjects/slice';
import { locationSlice } from './slices/location/slice';

const combinedReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [authSlice.name]: authSlice.reducer,
  [globalSlice.name]: globalSlice.reducer,
  [subjectsSlice.name]: subjectsSlice.reducer,
  [choicesSlice.name]: choicesSlice.reducer,
  [locationSlice.name]: locationSlice.reducer,
});

const setupReduxStore = () =>
  configureStore({
    reducer: combinedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        // serializableCheck: {
        //   ignoredActions: [
        //     'global/setAlert',
        //     'global/setAlertObject',
        //     'api/executeMutation/pending',
        //     'api/executeMutation/rejected',
        //   ],
        //   ignoredPaths: [
        //     'global.alert.buttons.0.handler',
        //     'global.alert.buttons.1.handler',
        //   ],
        //   ignoredActionPaths: [
        //     'payload.buttons.0.handler',
        //     'payload.buttons.1.handler',
        //     'meta.baseQueryMeta.request',
        //     'meta.baseQueryMeta.response',
        //   ],
        // },
      })
        .concat(api.middleware)
        .concat(rtkQueryErrorLogger),
  });

export const reduxStore = setupReduxStore();

export type ReduxRootState = ReturnType<
  ReturnType<typeof setupReduxStore>['getState']
>;
export type ReduxDispatch = ReturnType<typeof setupReduxStore>['dispatch'];
export type ReduxActionCreator<A = void, R = void> = (
  args: A
) => (dispatch: ReduxDispatch, getState: () => ReduxRootState) => Promise<R>;
