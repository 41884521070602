import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import { InputAdornment, TextField } from '@mui/material';
import { IonIcon } from '@ionic/react';
import s from './PlacesAutocomplete.module.scss';
import { locationOutline } from 'ionicons/icons';
import clsx from 'clsx';
import { LocationType } from './config';
import { useIsBigScreen } from '../../hooks/hook.big-screen';
import { useTypedDispatch, useTypedSelector } from '../../redux/hooks';
import { locationActionCreators } from '../../redux/slices/location/actionCreators';

const fixedData: LocationType[] = [
  {
    place_id: 'ChIJVXealLU_xkcRja_At0z9AGY',
    description: 'Amsterdam, Netherlands',
    structured_formatting: {
      main_text: 'Amsterdam',
      secondary_text: 'Netherlands',
      main_text_matched_substrings: [],
    },
    matched_substrings: [],
    terms: [],
    types: [],
  },
  {
    place_id: 'ChIJoQ8Q6NNB0S0RkOYkS7EPkSQ',
    description: 'Bali, Indonesia',
    structured_formatting: {
      main_text: 'Bali',
      secondary_text: 'Indonesia',
      main_text_matched_substrings: [],
    },
    matched_substrings: [],
    terms: [],
    types: [],
  },
  // {
  //   place_id: 'ChIJ5TCOcRaYpBIRCmZHTz37sEQ',
  //   description: 'Barcelona, Spain',
  //   structured_formatting: {
  //     main_text: 'Barcelona',
  //     secondary_text: 'Spain',
  //     main_text_matched_substrings: [],
  //   },
  //   matched_substrings: [],
  //   terms: [],
  //   types: [],
  // },
  {
    place_id: 'ChIJPV4oX_65j4ARVW8IJ6IJUYs',
    description: 'California, USA',
    structured_formatting: {
      main_text: 'California',
      secondary_text: 'USA',
      main_text_matched_substrings: [],
    },
    matched_substrings: [],
    terms: [],
    types: [],
  },
  {
    place_id: 'ChIJRcbZaklDXz4RYlEphFBu5r0',
    description: 'Dubai - United Arab Emirates',
    structured_formatting: {
      main_text: 'Dubai',
      secondary_text: 'United Arab Emirates',
      main_text_matched_substrings: [],
    },
    matched_substrings: [],
    terms: [],
    types: [],
  },
];

const fixedDataPlacesIds = fixedData.map(d => d.place_id);

export const PlacesAutocomplete = ({
  mode,
  onSelect,
  height,
}: {
  mode?: 'landingCustomer' | 'landingBusiness' | 'header';
  onSelect?: (option: LocationType) => void;
  height?: string;
}) => {
  const { isBigScreen } = useIsBigScreen();

  const {
    ready,
    value,
    suggestions: { data, loading },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['(regions)'],
    },
    debounce: 300,
  });

  const dispatch = useTypedDispatch();
  const { selectedLocation, detectedLocation } = useTypedSelector(
    s => s.location
  );

  const handleSelect = (value: LocationType) => {
    setValue(value.description, false);
    dispatch(locationActionCreators.selectLocation(value));
    clearSuggestions();
    onSelect?.(value);
    getGeocode({ address: value.description }).then(results => {
      const { lat, lng } = getLatLng(results[0]);
      console.log('📍 Coordinates: ', { lat, lng, value });
    });
  };

  return (
    <Autocomplete
      value={selectedLocation || detectedLocation || (null as any)}
      slotProps={{
        // popupIndicator: { style: { display: value ? 'none' : 'flex' } },
        paper: {
          style: {
            maxHeight: mode === 'header' ? undefined : '140px',
            overflow: 'auto',
          },
        },
      }}
      disableClearable
      sx={{
        width: '100%',
        height: '100%',
        '& fieldset': { borderRadius: mode === 'header' ? '10px' : '30px' },
        '& .MuiSvgIcon-root': {
          color: mode !== 'landingBusiness' ? 'white' : 'black',
        },

        // No text elipsis
        '& .MuiInputBase-input': {
          textOverflow: 'clip',
          whiteSpace: 'normal',
          overflow: 'visible',
          display: 'block',
        },

        '.MuiInputBase-root': {
          fontSize: mode === 'header' ? '16px' : isBigScreen ? '20px' : '16px',
          height: height || (isBigScreen ? '56px' : '48px'),
          paddingRight: '30px !important',
          paddingLeft: '12px !important',
        },

        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: mode === 'landingCustomer' ? 'white' : '#333131',
          },
          '&:hover fieldset': {
            borderColor: 'rgb(150, 150, 150)',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'rgb(150, 150, 150)',
          },
        },
      }}
      options={[
        ...fixedData.filter(d =>
          d.description.toLowerCase().startsWith(value.toLowerCase())
        ),
        ...data.filter(d => !fixedDataPlacesIds.includes(d.place_id)),
      ]}
      getOptionKey={option => option.place_id}
      getOptionLabel={option => option?.structured_formatting?.main_text}
      loading={!ready || loading}
      onChange={(_, v) => (v ? handleSelect(v) : null)}
      onInputChange={(_, newValue) => setValue(newValue)}
      renderOption={({ key, ...props }, option: LocationType) => (
        <li key={key} {...props}>
          <p>
            <span style={{ color: 'black' }}>
              {option.structured_formatting?.main_text}
            </span>
            <span
              style={{
                color: 'grey',
                marginLeft: '6px',
                fontSize: '13px',
                marginBottom: '-2px',
              }}
            >
              {option.structured_formatting?.secondary_text}
            </span>
          </p>
        </li>
      )}
      noOptionsText={null}
      renderInput={params => (
        <TextField
          {...params}
          sx={{
            input: { color: mode !== 'landingBusiness' ? 'white' : 'black' },
          }}
          fullWidth
          slotProps={{
            input: {
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: 0 }}>
                  <IonIcon
                    icon={locationOutline}
                    className={clsx(
                      s.LocationIcon,
                      mode === 'header' ? s.LocationIcon__mini : undefined,
                      mode === 'landingBusiness'
                        ? s.LocationIcon__black
                        : undefined
                    )}
                  />
                </InputAdornment>
              ),
            },
          }}
        />
      )}
    />
  );
};
