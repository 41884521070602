import { useState } from 'react';
import Page from '../../../components/Page/Page';
import { User } from '../../../models/user';
import { useIonRouter } from '@ionic/react';

type Chat = {
  id: string;
  members: User[];
  type: 'private' | 'group' | 'support';
  updatedAt: string;
  lastMessage: string;
};

const initialChats: Chat[] = [
  {
    id: '1',
    members: [],
    type: 'private',
    updatedAt: '20 dec',
    lastMessage: '10 dec',
  },
];

export const UserEventsMessagesPage: React.FC = () => {
  const [chats, setChats] = useState<Chat[]>([]);

  const router = useIonRouter();

  return (
    <Page headerProps={{ title: 'Messages' }}>
      {/* <p>TODO</p>

      <p onClick={() => router.push('/events/messages/<chatID>')}>Chat 1</p>
      <p>Chat 2</p>
      <p>Chat 3</p> */}
    </Page>
  );
};
