import { useRouteMatch } from 'react-router';
import { useTypedSelector } from '../../redux/hooks';
import { SubjectGeneric } from '../../redux/slices/subjects/slice';
import PermissionRequired from '../Auth/AuthRequired/PermissionRequired';

interface BusinessParams {
  businessId: string;
}

interface OrganizerParams {
  organizerId: string;
}

type Props = { children?: React.ReactNode };

/*
 * Used for Business and Organizer Apps
 */
const PermissionLayer: React.FC<Props> = ({ children }) => {
  const businessSet = useTypedSelector(s => s.subjects.businessSet)!;
  const organizerSet = useTypedSelector(s => s.subjects.organizerSet)!;

  const businessMatch = useRouteMatch('/business/:businessId');
  const organizerMatch = useRouteMatch('/organizer/:organizerId');

  let isPermissionExist = false;
  let activeSubject: SubjectGeneric;
  if (businessMatch) {
    const businessId = (businessMatch.params as BusinessParams).businessId;
    isPermissionExist = !!businessSet.find(
      business => business.id === businessId
    );
    activeSubject = {
      contentType: 'business',
      objectId: businessId,
    };
  } else if (organizerMatch) {
    const organizerId = (organizerMatch.params as OrganizerParams).organizerId;
    isPermissionExist = !!organizerSet.find(
      organizer => organizer.id === organizerId
    );
    activeSubject = {
      contentType: 'organizer',
      objectId: organizerId,
    };
  } else {
    throw new Error('Unhandled case');
  }

  if (isPermissionExist) {
    return <>{children}</>;
  } else {
    return <PermissionRequired />;
  }
};

export default PermissionLayer;
