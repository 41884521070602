import React from 'react';
import s from './Footer.module.scss';
import { AnchorEnum, processAnchorClick } from '../HeaderMobile/config';
import { useIonRouter } from '@ionic/react';
import { useLandingPageContext } from '../../../context';
import { Link } from 'react-router-dom';
import { BUSINESS_LANDING_PAGE_URL } from '../../../contstants';

export const Footer = () => {
  const { source } = useLandingPageContext();
  const router = useIonRouter();

  const clickAnchor = (anchor: AnchorEnum, block: 'customer' | 'business') => {
    if (source === block) {
      processAnchorClick(anchor);
    } else {
      const nextUrl = source === 'customer' ? BUSINESS_LANDING_PAGE_URL : '/';
      window.location.href = `${nextUrl}?allowVisitLanding=1#${anchor}`;
    }
  };

  const linkProps = { target: '_blank', rel: 'noopener noreferrer' } as const;

  return (
    <footer className={s.footer}>
      <div className={s.container}>
        <div className={s.count2}>
          <div onClick={() => processAnchorClick(AnchorEnum.ABOUT)}>
            <img src="./svg/appLogo_White.svg" className={s.logo} alt="logo" />
          </div>
          <div className={s.links}>
            <a
              href="https://www.instagram.com/eventmaker.app/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="./svg/instagram.svg" alt="instagram" />
            </a>
            <a
              href="https://www.facebook.com/people/Eventmaker/61554043888526/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="./svg/facebook.svg" alt="facebook" />
            </a>
            <a>
              <img src="./svg/twitter.svg" alt="twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/eventmaker-app/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="./svg/linkedin.svg" alt="linkedin" />
            </a>
          </div>
          <div className={s.download}>
            <a
              href="https://apps.apple.com/app/eventmaker-vendors/id6479720031"
              target="_blank"
              rel="noreferrer"
            >
              <img src="./svg/appstoreWhite.svg" alt="appstore" />
            </a>
            <a>
              <img src="./svg/googleplayWhite.svg" alt="googleplay" />
            </a>
          </div>
        </div>

        {/* Customers */}
        <ul>
          <li className={s.nav}>
            <p>For Clients</p>
          </li>
          <li>
            <p onClick={() => clickAnchor(AnchorEnum.ABOUT, 'customer')}>
              About
            </p>
          </li>
          <li>
            <p onClick={() => clickAnchor(AnchorEnum.REVIEWS, 'customer')}>
              Reviews
            </p>
          </li>
          <li>
            <p onClick={() => clickAnchor(AnchorEnum.FORM_REQUEST, 'customer')}>
              Consultation
            </p>
          </li>
          <li>
            <p onClick={() => clickAnchor(AnchorEnum.FAQ, 'customer')}>FAQ</p>
          </li>
        </ul>

        {/* Businesses */}
        <ul>
          <li className={s.nav}>
            <p>For Businesses</p>
          </li>
          <li>
            <p onClick={() => clickAnchor(AnchorEnum.ABOUT, 'business')}>
              About platform
            </p>
          </li>
          <li>
            <p onClick={() => clickAnchor(AnchorEnum.HOW_WORKS, 'business')}>
              How it works
            </p>
          </li>
          <li>
            <p onClick={() => clickAnchor(AnchorEnum.FORM_REQUEST, 'business')}>
              Transfer your catalog
            </p>
          </li>
          <li>
            <p onClick={() => clickAnchor(AnchorEnum.FAQ, 'business')}>
              Business FAQ
            </p>
          </li>
        </ul>

        {/* Desktop */}
        <ul className={s.nav_desktop}>
          <li className={s.nav}>
            <p>Company</p>
          </li>
          <li onClick={() => router.push('/about')}>
            <p>About company</p>
          </li>

          <Link to="/terms" {...linkProps}>
            <li>
              <p>Terms of usage</p>
            </li>
          </Link>

          <Link to="/privacy" {...linkProps}>
            <li>
              <p>Privacy policy</p>
            </li>
          </Link>

          <li onClick={() => router.push('/support')}>
            <p>Contact support</p>
          </li>
        </ul>

        {/* Mobile */}
        <ul className={s.nav_mobile}>
          <li className={s.nav}>
            <p>Company</p>
          </li>
          <li onClick={() => router.push('/about')}>
            <p>About company</p>
          </li>
          <li onClick={() => router.push('/support')}>
            <p>Contact support</p>
          </li>
        </ul>

        <ul className={s.nav_mobile}>
          <li className={s.empty}>
            <p></p>
          </li>

          <Link to="/terms" {...linkProps}>
            <li>
              <p>Terms of usage</p>
            </li>
          </Link>

          <Link to="/privacy" {...linkProps}>
            <li>
              <p>Privacy policy</p>
            </li>
          </Link>
        </ul>
      </div>
    </footer>
  );
};
