import {
  COUNTRIES,
  Place,
  UNITED_STATES,
} from '../../../components/Location/config';
import { getNearestPlace } from '../../../components/Location/helpers';
import { LocationType } from '../../../components/Location/config';
import { ReduxActionCreator } from '../../store';
import { locationSlice } from './slice';

const detectLocation: ReduxActionCreator = variables => {
  return async (dispatch, getState) => {
    fetch(
      `https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyD7xe2BVLq5j_WrSdsh4ukh9d80k68vS4M`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }
    )
      .then(response => response.json())
      .then(data => {
        const location = data.location as { lat: number; lng: number };
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location }, (results, status) => {
          if (status !== 'OK' || !results || !results[0]) {
            throw new Error('Can not detect place.');
          }

          // Detect country
          let countryName = '';
          const countryComponent = results[0].address_components.find(d =>
            d.types.includes('country')
          );

          if (countryComponent && countryComponent.types.includes('country')) {
            countryName = countryComponent.long_name;
          }

          // List of possible places within country
          let places: Place[] = [];
          if (countryName === 'United States') {
            let state = '';
            for (let component of results[0].address_components.reverse()) {
              if (component.types.includes('administrative_area_level_1')) {
                state = component.long_name;
              }
            }
            places = state in UNITED_STATES ? UNITED_STATES[state] : [];
          } else if (countryName in COUNTRIES) {
            places = COUNTRIES[countryName];
          }

          // Find nearest place
          let placeName = '';
          if (places.length === 0) {
            placeName = countryName;
          } else if (places.length === 1) {
            placeName = places[0].name;
          } else {
            const { closestPlace } = getNearestPlace(places, location);
            placeName = closestPlace?.name || '';
          }

          if (placeName) {
            const autocompleteService =
              new google.maps.places.AutocompleteService();
            autocompleteService.getPlacePredictions(
              { input: placeName, types: ['(regions)'] },
              (predictions, status) => {
                if (
                  status === google.maps.places.PlacesServiceStatus.OK &&
                  predictions?.length
                ) {
                  const prediction = predictions[0];
                  dispatch(
                    locationSlice.actions.setDetectedLocation(prediction)
                  );
                  dispatch(locationSlice.actions.setIsDetecting(false));
                }
              }
            );
          } else {
            dispatch(locationSlice.actions.setIsDetecting(false));
          }
        });
      })
      .catch(err => {
        dispatch(locationSlice.actions.setIsDetecting(false));
        throw err;
      });
  };
};

const selectLocation: ReduxActionCreator<LocationType> = variables => {
  return async (dispatch, getState) => {
    dispatch(locationSlice.actions.setSelectedLocation(variables));
  };
};

export const locationActionCreators = {
  detectLocation,
  selectLocation,
};
