import { AuthStorageKeys } from './redux/slices/auth/slice';

export enum AnalyticEventsEnum {
  /* STANDARD */
  CONTACT = 'Contact', // whats app button
  INITIATE_CHEKOUT = 'InitiateCheckout', // book now button
  LEAD = 'Lead', // HelpRequest submitted
  SEARCH = 'Search', // Search button clicked
  VIEW_CONTENT = 'ViewContent', // Same as VIEW_CATALOG_PAGE

  /* CUSTOM */
  VIEW_CATALOG_PAGE = 'ViewCatalogPage',
  VIEW_PRODUCT_DETAIL_PAGE = 'ViewProductDetailPage',
  VIEW_BUSINESS_DETAIL_PAGE = 'ViewBusinessDetailPage',
  VIEW_ASSISTANCE_PAGE = 'ViewAssistancePage',

  VISIT_BUSINESS_LANDING = 'VisitBusinessLanding',
  VISIT_CUSTOMER_LANDING = 'VisitCustomerLanding',

  CLICK_MESSAGE_ICON = 'ClickMessageIcon',
  CLICK_CONTACT_BUTTON = 'ClickContactButton',

  REDIRECT_FROM_INST = 'RedirectFromInst',
  REDIRECT_FROM_INST_ASSISTANCE = 'RedirectFromInstAssistance',
  REDIRECT_FROM_INST_CATALOG = 'RedirectFromInstCatalog',
  REDIRECT_FROM_INST_CATALOG_PRODUCT = 'RedirectFromInstCatalogProduct',
  REDIRECT_FROM_INST_WHATSAPP_CONTACT = 'RedirectFromInstWhatsappContact',
  REDIRECT_FROM_INST_WHATSAPP_CONCIERGE = 'RedirectFromInstWhatsappConcierge',
}

export const trackEvent = (eventName: AnalyticEventsEnum) => {
  console.log(`[Analytic] ${eventName}`);

  if (
    localStorage.getItem(AuthStorageKeys.USER)?.includes('@eventmaker.app') ||
    localStorage.getItem('disableAnalyticForTest') ||
    process.env.REACT_APP_ENVIRONMENT !== 'prod'
  ) {
    return;
  }

  let action: 'track' | 'trackCustom';

  if (
    [
      AnalyticEventsEnum.CONTACT,
      AnalyticEventsEnum.INITIATE_CHEKOUT,
      AnalyticEventsEnum.LEAD,
      AnalyticEventsEnum.SEARCH,
      AnalyticEventsEnum.VIEW_CONTENT,
    ].includes(eventName)
  ) {
    action = 'track';
  } else {
    action = 'trackCustom';
  }

  (window as any).fbq(action, eventName);
};
