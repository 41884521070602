import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonToolbar,
} from '@ionic/react';
import clsx from 'clsx';
import { closeOutline } from 'ionicons/icons';
import { PlacesAutocomplete } from '../Location/PlacesAutocomplete';
import Button from '../Buttons/Button/Button';

import sg from '../../styles/global.module.scss';
import s from './LocationUnavailable.module.scss';

export const PhaseOne = ({
  close,
  nextPhase,
}: {
  close: () => void;
  nextPhase: () => void;
}) => {
  return (
    <>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="end">
            <IonButton onClick={close}>
              <IonIcon
                icon={closeOutline}
                className={clsx(s.Modal__CloseButton)}
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div style={{ padding: '10px' }} className={clsx(sg.Stack_Vertical)}>
        <p
          style={{
            fontWeight: 700,
            fontSize: '30px',
            width: '100%',
            textAlign: 'center',
            color: 'grey',
          }}
        >
          Where?
        </p>

        <p
          className={clsx(s.Text__Content)}
          style={{ padding: '10px 0', lineHeight: '30px' }}
        >
          Right now we operate only in Bali 🌴 {'\n'}
          Where else would you like to use our platform?
        </p>

        <div
          style={{
            width: '300px',
            maxWidth: '100%',
            marginTop: '10px',
            marginBottom: '15px',
            alignSelf: 'center',
          }}
        >
          <PlacesAutocomplete mode="header" />
        </div>

        <div className={clsx(sg.Stack_Horizontal)}>
          <Button
            maxWidth="200px"
            text="Vote"
            color="secondary"
            fill="solid"
            onClick={nextPhase}
          />
        </div>
      </div>
    </>
  );
};
