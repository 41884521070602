import React, { useEffect } from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route, useLocation } from 'react-router';
import { animationSlide } from '../../utils/animation';
import { AnalyticEventsEnum, trackEvent } from '../../Analytics';
import { WHATS_APP_PHONE_NUMBER } from '../../utils/phoneNumber';
import Page from '../../components/Page/Page';

const InstagramRedirectRoutes: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const sourceLink = location.pathname + location.search;

    console.log('Redirect from instagram link', { sourceLink });

    if (location.pathname === '/inst') {
      trackEvent(AnalyticEventsEnum.REDIRECT_FROM_INST);
    } else if (location.pathname === '/inst/assistance') {
      trackEvent(AnalyticEventsEnum.REDIRECT_FROM_INST_ASSISTANCE);
    } else if (location.pathname === '/inst/whatsapp/contact') {
      trackEvent(AnalyticEventsEnum.REDIRECT_FROM_INST_WHATSAPP_CONTACT);
    } else if (location.pathname === '/inst/whatsapp/concierge') {
      trackEvent(AnalyticEventsEnum.REDIRECT_FROM_INST_WHATSAPP_CONCIERGE);
      // Links to catalog
    } else if (location.pathname === '/inst/catalog') {
      trackEvent(AnalyticEventsEnum.REDIRECT_FROM_INST_CATALOG);
    } else {
      trackEvent(AnalyticEventsEnum.REDIRECT_FROM_INST_CATALOG_PRODUCT);
    }
  }, [location]);

  return (
    <IonRouterOutlet ionPage animation={animationSlide}>
      {/* Landing */}
      <Route exact path="/inst">
        <Redirect to="/" />
      </Route>

      {/* Assistance */}
      <Route exact path="/inst/assistance">
        <Redirect to="/catalog/assistance/request" />
      </Route>

      {/* Catalog Main Page */}
      <Route exact path="/inst/catalog">
        <Redirect to="/catalog" />
      </Route>

      {/* Catalog Main Page */}
      <Route
        exact
        path="/inst/whatsapp/:Variant"
        render={props => {
          const variant = props.match.params.Variant as string;
          let text = '';
          if (variant === 'contact') {
            text = 'Hello, ';
          } else if (variant === 'concierge') {
            text = 'Hello, I want you to organize my event';
          }

          window.location.replace(
            `https://wa.me/${WHATS_APP_PHONE_NUMBER}?text=${encodeURI(text)}`
          );
          return <Page />;
        }}
      />

      {/* Catalog Specific Service */}
      <Route
        exact
        path="/inst/catalog/:ProductCategoryName"
        render={props => (
          <Redirect
            to={`/catalog?type=product&product_category_id=${props.match.params.ProductCategoryName}`}
          />
        )}
      />
    </IonRouterOutlet>
  );
};

export default React.memo(InstagramRedirectRoutes);
