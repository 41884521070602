import React from 'react';
import clsx from 'clsx';
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonToolbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import Button from '../Buttons/Button/Button';
import Input from '../Inputs/Input/Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from '../Form/Form';
import * as Yup from 'yup';
import { useSumbitFormCallback } from '../Form/submit.hook';
import { validationErrors } from '../../utils/validators';
import Centralizer from '../Centralizer/Centralizer';
import { useTypedDispatch, useTypedSelector } from '../../redux/hooks';
import { globalActionCreators } from '../../redux/slices/global/actionCreator';

import sg from '../../styles/global.module.scss';
import s from './LocationUnavailable.module.scss';

type FormType = { email: string };

const validation = {
  email: Yup.string()
    .email(validationErrors.EMAIL)
    .required(validationErrors.REQUIRED)
    .nullable(),
};

const validationSchema: Yup.SchemaOf<Partial<FormType>> =
  Yup.object().shape(validation);

export const PhaseTwo = ({ close }: { close: () => void }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted, isLoading },
    reset,
    trigger,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
    } as Partial<FormType>,
  });

  const dispatch = useTypedDispatch();

  const submitCallback = async ({ email }: FormType) => {
    // TODO ASAP: connect backend
    close();
    dispatch(
      globalActionCreators.setToastObject({
        message:
          "We'll keep you posted! In the meantime, you can explore Bali catalog",
        color: 'success',
        duration: 10000,
      })
    );
  };

  const { onSubmit } = useSumbitFormCallback<FormType>({
    submitCallback,
    handleSubmit,
    reset,
    trigger,
    setValue,
    isSubmitted,
    hideToast: true,
  });

  const { selectedLocation, detectedLocation } = useTypedSelector(
    s => s.location
  );

  const getSecondCountryName = () => {
    const location = selectedLocation || detectedLocation;
    const cityAndCountry = [
      location?.structured_formatting.main_text,
      location?.structured_formatting.secondary_text,
    ];

    if (
      cityAndCountry.includes('UK') ||
      cityAndCountry.includes('United Kingdom')
    ) {
      return 'United Kingdom';
    }

    if (
      cityAndCountry.includes('UAE') ||
      cityAndCountry.includes('United Arab Emirates')
    ) {
      return 'Dubai';
    }

    if (
      cityAndCountry.includes('Russia') ||
      cityAndCountry.includes('Россия')
    ) {
      return 'Russia';
    }

    return 'Europe';
  };

  return (
    <div>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="end">
            <IonButton onClick={close}>
              <IonIcon
                icon={closeOutline}
                className={clsx(s.Modal__CloseButton)}
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div style={{ padding: '10px' }} className={clsx(sg.Stack_Vertical)}>
        <p
          style={{
            fontWeight: 700,
            fontSize: '30px',
            width: '100%',
            textAlign: 'center',
            color: 'pink',
          }}
        >
          IN 2025
        </p>

        <Form loading={isLoading} onSubmit={onSubmit} hidePrompt>
          <p
            className={clsx(s.Text__Content)}
            style={{
              paddingTop: '0',
              lineHeight: '30px',
              color: 'pink',
              fontWeight: 600,
            }}
          >
            we're launching in USA and {getSecondCountryName()}
          </p>

          <p
            className={clsx(s.Text__Content)}
            style={{
              paddingTop: '20px',
              paddingBottom: '5px',
              lineHeight: '30px',
            }}
          >
            Should we notify you when our platform {'\n'}is available in{' '}
            {
              (selectedLocation || detectedLocation)?.structured_formatting
                .main_text
            }
            ?
          </p>

          <Centralizer>
            <div
              style={{
                width: '300px',
                maxWidth: '100%',
                marginTop: '10px',
                marginBottom: '10px',
                alignSelf: 'center',
              }}
            >
              <Input
                autocomplete="email"
                label="Email"
                type="email"
                inputmode="email"
                _name="email"
                _errorText={errors.email?.message}
                _register={register}
              />
              <p className={s.Input__UnderLabel}>No spam - we hate it too</p>
            </div>
          </Centralizer>

          <div
            className={clsx(sg.Stack_Horizontal)}
            style={{ marginTop: '10px' }}
          >
            <Button
              maxWidth="200px"
              text="Notify me!"
              color="secondary"
              fill="solid"
              type="submit"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};
