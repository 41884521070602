import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router';
import UserCatalogPage from './UserCatalogPage';
import UserProductPage from './UserProductPage';
import { animationOpacity } from '../../../utils/animation';
import UserBusinessPage from './UserBusinessPage';
import UserAssistancePage from './UserAssistancePage';

const UserCatalogRoutes: React.FC = () => {
  return (
    <IonRouterOutlet ionPage animation={animationOpacity}>
      <Route exact path="/catalog/assistance/request">
        <UserAssistancePage />
      </Route>

      <Route path="/catalog/business/:businessId">
        <UserBusinessPage />
      </Route>

      <Route exact path="/catalog/:productId">
        <UserProductPage />
      </Route>

      <Route exact path="/catalog">
        <UserCatalogPage />
      </Route>
    </IonRouterOutlet>
  );
};

export default React.memo(UserCatalogRoutes);
