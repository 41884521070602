import clsx from 'clsx';
import s from './AuthRequired.module.scss';
import sg from '../../../styles/global.module.scss';
import React from 'react';
import Button from '../../Buttons/Button/Button';
import Page from '../../Page/Page';
import CardAuth from '../CardAuth/CardAuth';
import { useTypedSelector } from '../../../redux/hooks';
import { Link } from 'react-router-dom';
import { isPlatform } from '@ionic/core';

const PermissionRequired: React.FC = () => {
  const email = useTypedSelector(s => s.auth.user?.email);

  return (
    <Page
      enableVerticalCentralization={true}
      enableImageBackground={true}
      pageLimitSize="350px"
    >
      <CardAuth>
        <div className={clsx(sg.Stack_Vertical, sg.Gap_20)}>
          <p>
            Your account <i>{`<${email}>`}</i> does not have access to requested
            page.
          </p>
          <p style={{ whiteSpace: 'pre-wrap' }}>
            Contact the person who shared the link with you and ask for access.
            If you use multiple accounts to login into Eventmaker you can press
            "Logout" button on settings page and login under correct account.
          </p>

          <div>
            {!isPlatform('capacitor') && (
              <Link to={{ pathname: '/', state: { allowVisitLandning: true } }}>
                <Button
                  text="About Eventmaker"
                  fill="outline"
                  type="button"
                  color="primary"
                  maxWidth="200px"
                  width="90%"
                  className={clsx(s.Button_unique)}
                />
              </Link>
            )}

            <Button
              text="To main page"
              fill="solid"
              type="button"
              color="secondary"
              routerLink="/catalog"
              maxWidth="200px"
              width="90%"
            />
          </div>
        </div>
      </CardAuth>
    </Page>
  );
};

export default PermissionRequired;
