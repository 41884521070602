import clsx from 'clsx';
import s from './Spinner.module.scss';
import { IonSpinner } from '@ionic/react';
import Centralizer from '../Centralizer/Centralizer';
import { useEffect, useState } from 'react';

type Props = {
  text?: string;
  size?: number;
  hasCountdown?: boolean;
};

// hasCountdown specify if page has percentage loader indicator
// this necessary only because our BE starts for ~10 seconds (to save resourses)
// if will run faster (CPU always allocated) we can get rid off it
export const Spinner: React.FC<Props> = ({ text, size = 20, hasCountdown }) => {
  const [secondsLeft, setSecondsLeft] = useState(11);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (secondsLeft > 0) {
        setSecondsLeft(secondsLeft - 1);
      }
      if (secondsLeft === 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <Centralizer
      className={clsx(s.Centralizer_unique)}
      enableVerticalCentralization={true}
    >
      {hasCountdown && secondsLeft < 10 ? (
        <p>{100 - 10 * secondsLeft}%</p>
      ) : undefined}

      <IonSpinner
        name={hasCountdown ? 'dots' : 'circular'}
        className={clsx(s.Spinner)}
        style={
          size
            ? {
                height: `${size}px`,
                width: `${size}px`,
              }
            : undefined
        }
      />

      {text ? <p>{text}</p> : undefined}
    </Centralizer>
  );
};

export default Spinner;
