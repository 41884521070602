export const isBusinessFormPage = (pathname: string) => {
  const patterns = [
    /^\/b\/.*\/products\/create/,
    /^\/b\/.*\/products\/edit/,
    /^\/b\/.*\/settings\/account-data/,
  ];
  return patterns.some(pattern => pattern.test(pathname));
};

export const isUserFormPage = (pathname: string) =>
  ['/settings/register-business'].some(prefix => pathname.startsWith(prefix));
