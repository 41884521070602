import { useTypedDispatch, useTypedSelector } from '../../redux/hooks';
import { authActionCreators } from '../../redux/slices/auth/actionCreators';
import CardAuth from './CardAuth/CardAuth';
import SignIn from './SignIn/SignIn';
import SignUp from './SignUp/SignUp';
import EmailConfirmation from './EmailVerificationRequired/EmailVerificationRequired';
import Page from '../Page/Page';
import PasswordReset from './PasswordReset/PasswordReset';
import PasswordResetConfirm from './PasswordResetConfirm/PasswordResetConfirm';
import PasswordResetConfirmRequired from './PasswordResetConfirmRequired/PasswordResetConfirmRequired';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ButtonMicroWrapper from '../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';
import { SplashScreen } from '@capacitor/splash-screen';
import { authSlice } from '../../redux/slices/auth/slice';
import { useLocation } from 'react-router';
import s from './AuthWrapper.module.scss';
import clsx from 'clsx';
import Centralizer from '../Centralizer/Centralizer';
import { openUrlInWeb } from './helper';

type Props = {
  children?: React.ReactNode;
};

const AuthWrapper: React.FC<Props> = ({ children }) => {
  const dispatch = useTypedDispatch();
  const location = useLocation();
  const authPhase = useTypedSelector(s => s.auth.authPhase);

  const returnUrl = useMemo(() => {
    const r_url = new URLSearchParams(location.search).get('return_url');
    return decodeURI(r_url || '');
  }, [location.search]);

  const loginRedirectUrl = useTypedSelector(s => s.auth.loginRedirectUrl);
  const setLoginRedirectUrl = useCallback(() => {
    const currentUrl = location.pathname + location.search;
    dispatch(authSlice.actions.setLoginRedirectUrl(returnUrl || currentUrl));
  }, [dispatch, returnUrl, location.pathname, location.search]);

  const showSplash = useCallback(async () => {
    await SplashScreen.show({
      showDuration: 1000,
      fadeInDuration: 200,
      fadeOutDuration: 200,
      autoHide: true,
    });
  }, []);

  const [componentForWrapping, setComponentForWrapping] =
    useState<React.ReactNode>();

  useEffect(() => {
    if (authPhase === 'signUp_Page' || authPhase === 'signIn_Page') {
      setLoginRedirectUrl();
    }

    switch (authPhase) {
      case 'signUp_Page': {
        setComponentForWrapping(<SignUp />);
        break;
      }
      case 'signIn_Page': {
        setComponentForWrapping(<SignIn />);
        break;
      }
      case 'emailVerificationRequire_Page': {
        setComponentForWrapping(<EmailConfirmation />);
        break;
      }
      case 'emailVerificationProcessInBackground': {
        dispatch(authActionCreators.emailVerificationProcess());
        break;
      }
      case 'passwordReset_Page': {
        setComponentForWrapping(<PasswordReset />);
        break;
      }
      case 'passwordResetConfirmRequired_Page': {
        setComponentForWrapping(<PasswordResetConfirmRequired />);
        break;
      }
      case 'passwordResetConfirm_Page': {
        setComponentForWrapping(<PasswordResetConfirm />);
        break;
      }
      case 'authenticated':
      case 'notAuthenticated': {
        break;
      }
      case 'loginCompleted': {
        setComponentForWrapping('blank_screen');
        showSplash();
        setTimeout(() => {
          window.location.href = returnUrl || loginRedirectUrl || '';
        }, 200);
        break;
      }
      case 'logoutCompleted': {
        setTimeout(() => {
          window.location.href = '/settings';
        }, 200);
        break;
      }
      default: {
        throw new Error('Unhandled authPhase.' + authPhase);
      }
    }
  }, [
    authPhase,
    showSplash,
    dispatch,
    returnUrl,
    loginRedirectUrl,
    setLoginRedirectUrl,
  ]);

  if (componentForWrapping === 'blank_screen') {
    return null;
  }

  if (componentForWrapping) {
    const isNavigateBackPossible =
      authPhase === 'signIn_Page' ||
      authPhase === 'signUp_Page' ||
      authPhase === 'passwordResetConfirmRequired_Page' ||
      authPhase === 'emailVerificationRequire_Page';

    return (
      <Page enableVerticalCentralization enableImageBackground>
        <CardAuth className={s.CardAuth}>
          <ButtonMicroWrapper
            className={clsx(s.ButtonMicroWrapper)}
            onClick={() => {
              if (authPhase === 'passwordReset_Page') {
                dispatch(authActionCreators.goBackToMainLoginPage());
                return;
              }
              if (isNavigateBackPossible) {
                setComponentForWrapping(null);
                dispatch(
                  authActionCreators.changeAuthPhase('notAuthenticated')
                );
              } else {
                window.location.href = '/catalog';
              }
            }}
          >
            <p className={s.Button__exit}>X</p>
          </ButtonMicroWrapper>

          {componentForWrapping}
        </CardAuth>
      </Page>
    );
  } else {
    return <>{children}</>;
  }
};

export default AuthWrapper;
