import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Form from '../Form/Form';
import Input from '../Inputs/Input/Input';
import InputSelect from '../Inputs/InputSelect/InputSelect';
import InputSelectOption from '../Inputs/InputSelect/InputSelectOption';
import InputFile from '../Inputs/InputFile/InputFile';
import { Business } from '../../models/business';
import { useTypedSelector } from '../../redux/hooks';
import { useSumbitFormCallback } from '../Form/submit.hook';
import { validationErrors, validators } from '../../utils/validators';
import {
  StorageDirectoryData,
  FileCategoryEnum,
  UploadedFile,
} from '../../@shared/file';
import InputArea from '../Inputs/InputArea/InputArea';
import { IonCard } from '@ionic/react';

export type BusinessFormValues = Omit<Business, 'id' | 'status' | 'statistic'>;

type Props = {
  action: 'add' | 'edit';
  initialFormValues: Partial<BusinessFormValues>;
  submitCallback: (values: BusinessFormValues) => Promise<void>;
  storageDirectoryData: StorageDirectoryData;
};

const validationOrdered = {
  name: Yup.string().required(validationErrors.REQUIRED),
  details: Yup.string().required(validationErrors.REQUIRED),
  category: Yup.object()
    .shape({})
    .test('required', validationErrors.REQUIRED, (c: any) => {
      if (c === null) return true;
      return JSON.stringify(c) !== '{}';
    })
    .nullable(),
  categoryId: Yup.string().nullable(),
  categoryCustom: Yup.string()
    .default(() => null)
    .nullable()
    .when('category', {
      is: null,
      then: schema =>
        schema.required(
          'Describe what your company does or choose one of the categories provided above. '
        ),
    }),
  city: Yup.object()
    .shape({})
    .test('required', validationErrors.REQUIRED, (c: any) => {
      if (c === null) return true;
      return JSON.stringify(c) !== '{}';
    })
    .nullable(),
  cityId: Yup.string().nullable(),
  cityCustom: Yup.string()
    .default(() => null)
    .nullable()
    .when('city', {
      is: null,
      then: schema =>
        schema.required('Specify the city or choose it from the list above.'),
    }),
  uploadedFiles: validators.uploadedFilesNoMin,
  phoneNumber: validators.phoneNumber,
};

const validationSchema: Yup.SchemaOf<Partial<BusinessFormValues>> =
  Yup.object().shape(validationOrdered);

type FieldNameType = keyof typeof validationOrdered;

const BusinessForm: React.FC<Props> = ({
  action,
  submitCallback,
  storageDirectoryData,
  initialFormValues,
}) => {
  const user = useTypedSelector(s => s.auth.user);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitted, dirtyFields, defaultValues },
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFormValues,
    shouldFocusError: true,
  });

  const { onSubmit, updateUploadedFilesInForm } =
    useSumbitFormCallback<BusinessFormValues>({
      submitCallback,
      handleSubmit,
      reset,
      trigger,
      isSubmitted,
      setValue,
    });

  const categories = useTypedSelector(s => s.choices.businessCategorySet);
  const cities = useTypedSelector(s => s.choices.citySet);

  const city = watch('city' satisfies FieldNameType);
  const category = watch('category' satisfies FieldNameType);

  return (
    <Form
      showSubmitButton
      isDirty={!!Object.keys(dirtyFields).length}
      loadingStore="subjects"
      onSubmit={onSubmit}
    >
      {action === 'add' && (
        <IonCard style={{ margin: '10px 0px' }}>
          <p>
            You are going to register new business, and account{' '}
            <i>{`"${user?.email}"`}</i> would be the admin of it.
          </p>
        </IonCard>
      )}

      {/* ------- Name ------ */}
      <Input
        type="text"
        inputmode="text"
        label="Company name"
        maxlength={50}
        _name={'name' satisfies FieldNameType}
        _register={register}
        _errorText={errors.name?.message}
      />

      {/* ------- Business Category ------ */}
      <InputSelect
        label="Primary focus of business"
        onChange={() =>
          setValue('categoryCustom' satisfies FieldNameType, null)
        }
        _name={'category' satisfies FieldNameType}
        _register={register}
        _errorText={errors.category?.message}
      >
        {categories.map(category => (
          <InputSelectOption
            key={category.id}
            value={category}
            textPreview={category.name}
          />
        ))}
        <InputSelectOption key="null" value={null} textPreview="Other" />
      </InputSelect>

      {category === null && (
        <Input
          type="text"
          inputmode="text"
          maxlength={50}
          label="Primary focus of business"
          _name={'categoryCustom' satisfies FieldNameType}
          _register={register}
          _errorText={errors.categoryCustom?.message}
        />
      )}

      {/* ------- Details ------ */}
      <InputArea
        label="About business"
        _name={'details' satisfies FieldNameType}
        _register={register}
        _errorText={errors.details?.message}
      />

      {/* ------- City ------ */}
      <InputSelect
        label="City"
        onChange={() => setValue('cityCustom' satisfies FieldNameType, null)}
        _name={'city' satisfies FieldNameType}
        _register={register}
        _errorText={errors.city?.message}
      >
        {cities.map(city => (
          <InputSelectOption
            key={city.id}
            value={city}
            textPreview={city.name}
          />
        ))}
        <InputSelectOption key="null" value={null} textPreview="Other" />
      </InputSelect>

      {city === null && (
        <Input
          type="text"
          inputmode="text"
          maxlength={50}
          label="City"
          _name={'cityCustom' satisfies FieldNameType}
          _register={register}
          _errorText={errors.cityCustom?.message}
        />
      )}

      {/* ------- Files ------ */}
      <InputFile
        label="Avatar"
        maxCountOfFiles={1}
        allowedFileCategories={[FileCategoryEnum.IMAGE]}
        storageDirectoryData={storageDirectoryData}
        _name={'uploadedFiles' satisfies FieldNameType}
        _register={register}
        _errorText={errors.uploadedFiles?.message}
        __updateUploadedFilesInForm={updateUploadedFilesInForm}
        __initialValue={defaultValues?.uploadedFiles as UploadedFile[]}
      />

      {/* ------- Phone Number ------ */}
      <Input
        type="tel"
        inputmode="tel"
        maxlength={20}
        label="Whats App number"
        _name={'phoneNumber' satisfies FieldNameType}
        _register={register}
        _errorText={errors.phoneNumber?.message}
      />
    </Form>
  );
};

export default BusinessForm;
