import React from 'react';
import {
  AnchorEnum,
  getHeaderConfig,
  processAnchorClick,
  processItemClick,
} from '../HeaderMobile/config';
import s_Customer from './Header_Customer.module.scss';
import s_Business from './Header_Business.module.scss';
import { useLandingPageContext } from '../../../context';
import { IonIcon } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';

export const Header = () => {
  const { source, onActionClick, actionButtonName } = useLandingPageContext();

  const s = source === 'customer' ? s_Customer : s_Business;
  const config = getHeaderConfig({ source });

  return (
    <header className={s.header}>
      <nav className={s.nav}>
        <div
          className={s.logo}
          onClick={() => {
            if (source === 'customer') {
              processAnchorClick(AnchorEnum.ABOUT);
            } else {
              window.location.href = `/?allowVisitLanding=1#${AnchorEnum.ABOUT}`;
            }
          }}
        >
          <img
            alt=""
            src={
              source === 'customer'
                ? './svg/appLogo_White.svg'
                : './svg/appLogo_Black.svg'
            }
          />
        </div>

        <ul>
          {config.map((item, index) => (
            <li key={index}>
              <p onClick={() => processItemClick(item)}>{item.name}</p>
            </li>
          ))}
        </ul>

        <button className={s.button} onClick={onActionClick}>
          {actionButtonName}
          <IonIcon
            src={chevronForwardOutline}
            style={{
              color: source === 'customer' ? 'black' : 'white',
              fontSize: '22px',
            }}
          />
        </button>
      </nav>
    </header>
  );
};
