import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IonChip, IonIcon } from '@ionic/react';
import { useTypedSelector } from '../../redux/hooks';
import {
  BUTTON_WIDTH,
  LeftArrow,
  RightArrow,
} from '../HorizontalScrollHelpers/HorizontalScrollHelpers';
import {
  chevronDownOutline,
  chevronUpOutline,
  ellipse,
  filterOutline,
  searchOutline,
} from 'ionicons/icons';
import ButtonMicroWrapper from '../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';
import { Textfit } from 'react-textfit';
import Centralizer from '../Centralizer/Centralizer';
import SearchModalForm from '../SearchForm/SearchModal';
import { SearchTypeEnum, searchTypeMapper } from './interface';
import { SearchArgsType } from '../../redux/_types';
import { EventCategory } from '../../models/event';
import { ProductCategory } from '../../models/product';
import SearchString from '../SearchForm/SearchString';
import { isPlatform } from '@ionic/core';
import Spinner from '../Spinner/Spinner';
import clsx from 'clsx';
import { FixedSizeList, FixedSizeList as List } from 'react-window';
import { useSmoothScroll } from '../SearchContent/useSmoothScroll.hook';
import { SearchIcons } from '../../icons';
import Button from '../Buttons/Button/Button';

import './index.scss';
import s from './SearchBlock.module.scss';
import sg from '../../styles/global.module.scss';

type ColumnContext = {
  categories: (EventCategory | ProductCategory)[];
  setSelectedGenericCategory: React.Dispatch<
    React.SetStateAction<ProductCategory | EventCategory | undefined>
  >;
  highlitedGenericCategory: EventCategory | null | ProductCategory | undefined;
};

const Column = ({
  data: { categories, setSelectedGenericCategory, highlitedGenericCategory },
  index,
  style,
}: {
  data: ColumnContext;
  index: number;
  style: React.CSSProperties;
}) => {
  const genericCategory = categories[index];
  const isHighlited = highlitedGenericCategory?.id === genericCategory.id;

  return (
    <div
      key={genericCategory.id}
      style={style}
      className={clsx(
        s.Category__wrapper,
        isPlatform('desktop') ? s.Category__wrapper__desktop : undefined,
        isHighlited ? s.Category__wrapper__highlited : undefined
      )}
      onClick={() => {
        setSelectedGenericCategory(genericCategory);
      }}
    >
      <div
        style={{
          height: 'calc(100% - 18px)', // Compensate 2 lines of text
        }}
      >
        <Centralizer>
          <img
            className={clsx(s.Image__category)}
            alt=""
            style={{ filter: isHighlited ? 'grayscale(1)' : '' }}
            src={SearchIcons[genericCategory.id]}
          />
        </Centralizer>
      </div>

      <div className={clsx(s.Textfit__container)}>
        <Textfit mode="multi" className={clsx(s.Textfit)} min={12} max={14}>
          {genericCategory.name}
        </Textfit>
      </div>
    </div>
  );
};

interface SearchBlockProps {
  hideSearchString?: boolean;
  notCollapsed: boolean;
  setCollapsed: (val: boolean) => void;
  isAutoCollapseDisabled: React.MutableRefObject<boolean>;
  highlitedSearchType: SearchTypeEnum;
  selectedSearchType: SearchTypeEnum;
  setSelectedSearchType: React.Dispatch<React.SetStateAction<SearchTypeEnum>>;
  initialSearchArgs: SearchArgsType;
  setSearchArgs: React.Dispatch<React.SetStateAction<SearchArgsType>>;
  searchArgs: SearchArgsType;
  highlitedGenericCategory: EventCategory | null | ProductCategory | undefined;
  isSearchTermsApplied: boolean;
  isReady: boolean;
  isDataExists: boolean;
  lastTouchStart: React.MutableRefObject<{
    x: number;
    y: number;
  }>;
  isBusinessPage: boolean;
  fixUiOnSearch: () => Promise<void>;
  tableWrapperWidth: number;
  isBusinessPlatform?: boolean;
}

const ANIMATION_TIME = 250;

const MARGIN = isPlatform('desktop') ? 58 : 0;

const SearchBlock: React.FC<SearchBlockProps> = ({
  hideSearchString,
  notCollapsed,
  setCollapsed,
  isAutoCollapseDisabled,
  highlitedSearchType,
  selectedSearchType,
  setSelectedSearchType,
  initialSearchArgs,
  setSearchArgs,
  searchArgs,
  highlitedGenericCategory,
  isSearchTermsApplied,
  isReady,
  isDataExists,
  lastTouchStart,
  isBusinessPage,
  fixUiOnSearch,
  tableWrapperWidth,
  isBusinessPlatform,
}) => {
  const [selectedGenericCategory, setSelectedGenericCategory] = useState<
    EventCategory | ProductCategory | undefined
  >();

  const eventCategories = useTypedSelector(s => s.choices.eventCategorySet);
  const productCategories = useTypedSelector(s => s.choices.productCategorySet);
  const categories: (EventCategory | ProductCategory)[] = useMemo(() => {
    return selectedSearchType === SearchTypeEnum.BY_EVENT_CATEGORY
      ? eventCategories
      : selectedSearchType === SearchTypeEnum.BY_PRODUCT_CATEGORY
      ? productCategories
      : [];
  }, [eventCategories, productCategories, selectedSearchType]);

  const { listOuterRef, scrollBy, isRightEdge, isLeftEdge, trackEdges } =
    useSmoothScroll([selectedSearchType]);

  useEffect(() => {
    if (selectedSearchType !== SearchTypeEnum.BY_KEYWORDS) trackEdges(true);
  }, [selectedSearchType, trackEdges]);

  const getInitialIndex = useCallback(() => {
    const initialIndex =
      highlitedSearchType === selectedSearchType
        ? selectedSearchType === SearchTypeEnum.BY_EVENT_CATEGORY
          ? eventCategories.findIndex(
              c => c.id === highlitedGenericCategory?.id
            )
          : highlitedSearchType === SearchTypeEnum.BY_PRODUCT_CATEGORY
          ? productCategories.findIndex(
              c => c.id === highlitedGenericCategory?.id
            )
          : undefined
        : undefined;

    return initialIndex;
  }, [
    selectedSearchType,
    highlitedGenericCategory,
    highlitedSearchType,
    eventCategories,
    productCategories,
  ]);

  const listRef = useRef<FixedSizeList>(null);
  useEffect(() => {
    const initialIndex = getInitialIndex();
    if (listRef?.current && initialIndex) {
      setTimeout(() => listRef.current!.scrollToItem(initialIndex));
    }
  }, [listRef, getInitialIndex, highlitedSearchType]);

  const [width, height] = [100, 100];

  const heightBusy =
    (selectedSearchType !== SearchTypeEnum.BY_KEYWORDS ? height : 50) +
    40 + // ionchips
    60 + // header
    60 + // footer
    70; // extra compensation

  useEffect(() => {
    if (!isPlatform('mobile')) return;

    const func = (e: TouchEvent) => {
      const deltaX = e.touches[0].clientX - lastTouchStart.current.x;
      const deltaY = e.touches[0].clientY - lastTouchStart.current.y;
      const isVerticalScroll = Math.abs(deltaX) < Math.abs(deltaY);
      if (isVerticalScroll) {
        e.preventDefault();
      }
    };

    const elem = document.getElementById('MySearchBlock') as HTMLDivElement;

    elem.addEventListener('touchmove', func, { passive: false });
    return () => elem.removeEventListener('touchmove', func);
  }, [lastTouchStart]);

  // TODO ASAP: Make calculations of filter count more strict
  const hasFilterApplied =
    searchArgs.type !== 'event' || searchArgs.eventCategory;

  return (
    <tr>
      <td
        className={clsx(
          s.SearchBlock__td,
          hideSearchString ? s.SearchBlock__td__no_padding : undefined
        )}
        style={{
          transform: 'translateZ(0)', // fix flickering safari ?
        }}
      >
        <div
          id="MySearchBlock"
          className={clsx(
            s.SearchBlock__container,
            hideSearchString
              ? s.SearchBlock__container__no_box_shadow
              : undefined
          )}
          style={{
            marginLeft: MARGIN,
            marginRight: MARGIN,
          }}
        >
          <SearchModalForm
            selectedGenericCategory={selectedGenericCategory}
            closeModal={() => setSelectedGenericCategory(undefined)}
            searchType={selectedSearchType}
            initialSearchArgs={initialSearchArgs}
            setSearchArgs={setSearchArgs}
            fixUiOnSearch={fixUiOnSearch}
          />

          {!isBusinessPage &&
            (notCollapsed ? (
              <div className={clsx(sg.Stack_Horizontal)}>
                {Object.values(SearchTypeEnum).map(searchType => (
                  <IonChip
                    key={searchType}
                    mode="ios"
                    color="primary"
                    className={clsx(
                      s.IonChip,
                      'custom-ion-chip',
                      isPlatform('desktop') && searchType !== selectedSearchType
                        ? sg.Hover_Animation_Transparency
                        : ''
                    )}
                    outline={searchType === selectedSearchType}
                    onClick={() => {
                      setSelectedSearchType(searchType);
                    }}
                  >
                    {searchType !== SearchTypeEnum.BY_KEYWORDS ? (
                      <p>{searchTypeMapper[searchType]}</p>
                    ) : (
                      <IonIcon
                        icon={searchOutline}
                        style={{
                          fontSize: '24px',
                          marginRight: '5px',
                        }}
                      />
                    )}

                    {highlitedSearchType === searchType && (
                      <IonIcon
                        className={clsx(s.IonIcon__selected_chip)}
                        src={ellipse}
                      />
                    )}
                  </IonChip>
                ))}

                {
                  <div className={clsx(sg.Flex_On)}>
                    <ButtonMicroWrapper
                      disabled={
                        !(
                          selectedSearchType !== SearchTypeEnum.BY_KEYWORDS &&
                          selectedSearchType === highlitedSearchType &&
                          isDataExists
                        )
                      }
                      className={clsx(
                        'disableIosSafariSwipeBlocker',
                        s.ButtonMicroWrapper
                      )}
                      onClick={() => {
                        isAutoCollapseDisabled.current = notCollapsed;
                        setCollapsed(notCollapsed);
                      }}
                    >
                      <IonIcon
                        icon={
                          !notCollapsed ? chevronDownOutline : chevronUpOutline
                        }
                        className={clsx(s.IonIcon__collapse)}
                      />
                    </ButtonMicroWrapper>
                  </div>
                }
              </div>
            ) : (
              <Button
                text={`Change filters (${hasFilterApplied ? 1 : 0})`}
                color="primary"
                fill="clear"
                width="calc(100% - 10px)"
                onClick={() => setCollapsed(false)}
                logo={filterOutline}
              />
            ))}

          <div
            style={{
              animationDuration: ANIMATION_TIME + 'ms',
              transition: `all ${ANIMATION_TIME}ms ease-in`,
              ...(selectedSearchType !== SearchTypeEnum.BY_KEYWORDS && isReady
                ? {
                    height: notCollapsed ? height : 0,
                    opacity: notCollapsed ? 1 : 0,
                  }
                : {}),
            }}
          >
            {selectedSearchType !== SearchTypeEnum.BY_KEYWORDS ? (
              <div
                className={clsx(sg.Stack_Horizontal)}
                style={{ margin: isPlatform('desktop') ? '3px 0' : '5px' }}
              >
                {notCollapsed && (
                  <LeftArrow disabled={isLeftEdge} scrollBy={scrollBy} />
                )}

                <List
                  key={selectedSearchType}
                  ref={listRef}
                  layout="horizontal"
                  className={clsx(sg.DisableScrollbar)}
                  outerRef={listOuterRef}
                  height={height - 4} // compensation
                  style={{
                    display: notCollapsed ? 'block' : 'none',
                  }}
                  width={tableWrapperWidth - 2 * (MARGIN + BUTTON_WIDTH)}
                  itemCount={categories.length}
                  itemSize={width}
                  itemData={
                    {
                      categories,
                      setSelectedGenericCategory,
                      highlitedGenericCategory,
                    } satisfies ColumnContext
                  }
                >
                  {Column}
                </List>

                {notCollapsed && (
                  <RightArrow disabled={isRightEdge} scrollBy={scrollBy} />
                )}
              </div>
            ) : (
              !hideSearchString && (
                <SearchString
                  setSearchArgs={setSearchArgs}
                  fixUiOnSearch={fixUiOnSearch}
                  searchTerm={
                    searchArgs.type === SearchTypeEnum.BY_KEYWORDS
                      ? searchArgs.searchTerm
                      : ''
                  }
                />
              )
            )}
          </div>
        </div>

        {(!isSearchTermsApplied || !isReady || !isDataExists) && (
          <div
            style={{
              height: `calc(100vh - ${heightBusy}px)`,
              width: '100%',
              position: 'absolute',
            }}
          >
            <Centralizer enableVerticalCentralization>
              {!isSearchTermsApplied ? (
                <>
                  <p
                    style={{
                      color: 'grey',
                      marginBottom: '10px',
                      fontSize: 18,
                    }}
                  >
                    What are you looking for?
                  </p>

                  {selectedSearchType !== SearchTypeEnum.BY_KEYWORDS ? (
                    <p>Select one of the category above</p>
                  ) : (
                    <p style={{ textAlign: 'center', padding: '0 10px' }}>
                      You can always ask for help in the top right corner
                    </p>
                  )}
                </>
              ) : !isReady ? (
                <Spinner size={30} hasCountdown />
              ) : !isDataExists ? (
                <>
                  <p
                    style={{
                      color: 'grey',
                      marginBottom: '10px',
                      fontSize: 18,
                    }}
                  >
                    {isBusinessPlatform &&
                    searchArgs.type === SearchTypeEnum.BY_KEYWORDS &&
                    !searchArgs.searchTerm
                      ? "You don't have any listings yet"
                      : 'Nothing was found :('}
                  </p>

                  <p style={{ marginBottom: '5px', textAlign: 'center' }}>
                    {isBusinessPage
                      ? isBusinessPlatform
                        ? `Presss "+" button to add event services which you provide`
                        : 'A business does not have any offerings yet'
                      : 'You can always ask for help in the top right corner'}
                  </p>
                </>
              ) : (
                <p>
                  An error happened. Please, try one more time or contact
                  support.
                </p>
              )}
            </Centralizer>
          </div>
        )}
      </td>
    </tr>
  );
};

export default SearchBlock;
