import { Redirect } from 'react-router';
import { useTypedSelector } from './redux/hooks';

/* If user do not provide any path in url */
const AppDefaultRedirect: React.FC = () => {
  const activeSubject = useTypedSelector(s => s.subjects.activeSubject);

  if (activeSubject && activeSubject.contentType !== 'user') {
    const prefix =
      activeSubject.contentType === 'business' ? 'business' : 'organizer';
    return <Redirect to={`/${prefix}/${activeSubject.objectId!}/products`} />;
  }

  return <Redirect to="/catalog" />;
};

export default AppDefaultRedirect;
