import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Input from '../../Inputs/Input/Input';
import Button from '../../Buttons/Button/Button';
import Form from '../../Form/Form';
import { useTypedSelector } from '../../../redux/hooks';
import { validationErrors } from '../../../utils/validators';

export interface SignUpFormValues {
  email?: string;
  password1?: string;
  password2?: string;
}

interface SignUpFormProps extends SignUpFormValues {
  submitCallback: (values: SignUpFormValues) => void;
  children?: React.ReactNode;
}

let validationSchema = Yup.object({
  email: Yup.string()
    .email(validationErrors.EMAIL)
    .required(validationErrors.REQUIRED)
    .nullable(),
  password1: Yup.string()
    .required(validationErrors.REQUIRED)
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .nullable(),
  password2: Yup.string()
    .required(validationErrors.REQUIRED)
    .oneOf([Yup.ref('password1'), null], 'Passwords do not match'),
});

const SignUpForm: React.FC<SignUpFormProps> = ({
  children,
  submitCallback,
  ...initialFormValues
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFormValues,
  });

  const loading = useTypedSelector(s => s.auth.loading);

  return (
    <Form loading={loading} onSubmit={handleSubmit(submitCallback)}>
      {/* ------- Email ------ */}
      <Input
        applyWorkaround
        type="email"
        inputmode="email"
        maxlength={50}
        label="Email"
        autocomplete="email" // ? off
        _name="email"
        _errorText={errors.email?.message}
        _register={register}
        _setValue={setValue}
      />

      {/* ------- Password 1 ------ */}
      <Input
        applyWorkaround
        type="password"
        inputmode="text"
        maxlength={50}
        label="Password"
        autocomplete="new-password"
        _name="password1"
        _errorText={errors.password1?.message}
        _register={register}
        _setValue={setValue}
      />

      {/* ------- Password 2 ------ */}
      <Input
        applyWorkaround
        type="password"
        inputmode="text"
        maxlength={50}
        label="Repeat password"
        autocomplete="new-password"
        _name="password2"
        _errorText={errors.password2?.message}
        _register={register}
        _setValue={setValue}
      />

      {/* ------ Social Providers ------ */}
      <div style={{ marginTop: '25px' }}>{children}</div>

      {/* ------- Submit ------ */}
      <Button
        text="Continue"
        type="submit"
        color="secondary"
        fill="solid"
        loading={loading}
      />
    </Form>
  );
};

export default SignUpForm;
