import { Place } from './config';

// Function to calculate the Haversine distance
function haversine(lat1: number, lon1: number, lat2: number, lon2: number) {
  const R = 6371; // Radius of the Earth in kilometers
  const phi1 = (lat1 * Math.PI) / 180;
  const phi2 = (lat2 * Math.PI) / 180;
  const deltaPhi = ((lat2 - lat1) * Math.PI) / 180;
  const deltaLambda = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(deltaPhi / 2) ** 2 +
    Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in kilometers
}

export const getNearestPlace = (
  places: Place[],
  givenPoint: { lat: number; lng: number }
) => {
  let closestPlace: Place | null = null;
  let minDistance = Infinity;

  for (let p of places) {
    const distance = haversine(
      givenPoint.lat,
      givenPoint.lng,
      p.location.lat,
      p.location.lng
    );
    if (distance < minDistance) {
      minDistance = distance;
      closestPlace = p;
    }
  }

  return {
    closestPlace,
    minDistance,
  };
};
