import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocationType } from '../../../components/Location/config';

export enum LocationStorageKeys {
  DETECTED = 'location_detected',
  SELECTED = 'location_selected',
}

export interface LocationStoreState {
  isDetecting: boolean;
  detectedLocation: LocationType | null;
  selectedLocation: LocationType | null;
}

const initiaSubjectsState: LocationStoreState = {
  isDetecting: true,
  detectedLocation: localStorage.getItem(LocationStorageKeys.DETECTED)
    ? JSON.parse(localStorage.getItem(LocationStorageKeys.DETECTED)!)
    : [],
  selectedLocation: localStorage.getItem(LocationStorageKeys.SELECTED)
    ? JSON.parse(localStorage.getItem(LocationStorageKeys.SELECTED)!)
    : null,
};

export const locationSlice = createSlice({
  name: 'location',
  initialState: initiaSubjectsState,
  reducers: {
    setIsDetecting(state: LocationStoreState, action: PayloadAction<boolean>) {
      state.isDetecting = action.payload;
    },
    setDetectedLocation(
      state: LocationStoreState,
      action: PayloadAction<LocationType>
    ) {
      if (
        !state.detectedLocation ||
        state.detectedLocation.place_id !== action.payload.place_id
      ) {
        state.detectedLocation = action.payload;
        localStorage.setItem(
          LocationStorageKeys.DETECTED,
          JSON.stringify(action.payload)
        );
      }
    },
    setSelectedLocation(
      state: LocationStoreState,
      action: PayloadAction<LocationType>
    ) {
      if (
        !state.selectedLocation ||
        state.selectedLocation.place_id !== action.payload.place_id
      ) {
        state.selectedLocation = action.payload;
        localStorage.setItem(
          LocationStorageKeys.SELECTED,
          JSON.stringify(action.payload)
        );
      }
    },
  },
});
