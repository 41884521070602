import React, { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Page from '../../../components/Page/Page';
import { useCachedFromList, useTypedDispatch } from '../../../redux/hooks';
import ProductForm, {
  ProductFormValues,
} from '../../../components/ProductForm/ProductForm';
import {
  useAddProductMutation,
  useEditProductMutation,
  useGetBusinessQuery,
  useGetProductQuery,
} from '../../../redux/api';
import { useRouteMatch } from 'react-router';
import { StorageFolderEnum } from '../../../@shared/file';
import Spinner from '../../../components/Spinner/Spinner';
import {
  CurrenciesEnum,
  PriceTypeEnum,
  Product,
} from '../../../models/product';
import { globalSlice } from '../../../redux/slices/global/slice';
import { ToastPriorityEnum } from '../../../utils/enums';
import { useIonRouter } from '@ionic/react';

interface Props {
  action: 'add' | 'edit';
}

const BusinessProductPostPage: React.FC<Props> = ({ action }) => {
  const dispatch = useTypedDispatch();
  const router = useIonRouter();

  const match = useRouteMatch<{ businessId: string; productId?: string }>();
  const businessId = match.params.businessId;

  const productId = useMemo(
    () => (action === 'add' ? uuidv4() : match.params.productId || ''),
    [action, match.params.productId]
  );

  const cachedProduct = useCachedFromList<'Product'>({
    tagName: 'Product',
    objectId: productId,
  });

  const {
    data: fetchedProduct,
    isLoading,
    isFetching,
  } = useGetProductQuery(
    { id: productId },
    { skip: !!cachedProduct || action === 'add' }
  );

  const product = cachedProduct || fetchedProduct;

  const { data: business } = useGetBusinessQuery({ id: businessId });

  const [addTrigger, addMetadata] = useAddProductMutation();
  const [editTrigger, editMetadata] = useEditProductMutation();

  const submitCallback = useCallback(
    async (values: ProductFormValues) => {
      const body = {
        ...values,
        id: productId,
        business: businessId,
        category: values.category.id,
        uploadedFiles: values.uploadedFiles.map(x => x.id),
        suitableEventCategories: values.suitableEventCategories.map(x => x.id),
        priceMinRentTime:
          values.priceType === PriceTypeEnum.PER_HOUR
            ? values.priceMinRentTime
            : null,
      };

      let result:
        | {
            data: Product;
          }
        | {
            error: any;
          };

      if (action === 'add') {
        result = await addTrigger({ body });
      } else {
        result = await editTrigger({
          id: productId,
          body,
        });
      }

      if (!('error' in result)) {
        dispatch(
          globalSlice.actions.setToast({
            message: 'Successfully saved',
            priority: ToastPriorityEnum.HIGH,
            color: 'success',
          })
        );

        if (action === 'add') {
          setTimeout(
            () => router.push(`/business/${businessId}/products`, 'back'),
            100
          );
        }
      }
    },
    [action, productId, businessId, addTrigger, editTrigger, dispatch]
  );

  return (
    <Page
      key={productId}
      disableDefaultIonContent
      headerProps={{
        title: action === 'add' ? 'Add service' : 'Edit service',
      }}
    >
      {action === 'edit' && (isFetching || isLoading || !product) ? (
        <Spinner />
      ) : action === 'add' && !business ? (
        <Spinner />
      ) : (
        <ProductForm
          action={action}
          initialFormValues={
            action === 'add'
              ? {
                  category: undefined,
                  subcategories: null,
                  uploadedFiles: [],
                  title: '',
                  details: '',
                  priceCurrency:
                    (business!.city?.country.currency as CurrenciesEnum) ||
                    'USD',
                  priceType: null,
                  priceTo: '',
                  priceFrom: '',
                  priceMinRentTime: '',
                  priceDescription: '',
                  peopleFrom: '',
                  peopleTo: '',
                  suitableEventCategories: [],
                }
              : {
                  category: product!.category,
                  subcategories: product!.subcategories,
                  uploadedFiles: product!.uploadedFiles,
                  title: product!.title,
                  details: product!.details,
                  priceCurrency: product?.priceCurrency,
                  priceType: product!.priceType,
                  priceTo: product!.priceTo,
                  priceFrom: product!.priceFrom,
                  priceMinRentTime: product!.priceMinRentTime,
                  priceDescription: product!.priceDescription,
                  peopleFrom: product!.peopleFrom,
                  peopleTo: product!.peopleTo,
                  suitableEventCategories: product!.suitableEventCategories,
                }
          }
          submitCallback={submitCallback}
          storageDirectoryData={{
            storageFolder: StorageFolderEnum.PRODUCT_MEDIA,
            businessId,
            productId,
          }}
          isLoading={addMetadata.isLoading || editMetadata.isLoading}
        />
      )}
    </Page>
  );
};

export default BusinessProductPostPage;
