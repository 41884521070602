import React, { useState } from 'react';
import clsx from 'clsx';
import { IonModal } from '@ionic/react';
import s from '../ContactInfoModal/ContactInfoModal.module.scss';
import { IS_INSTAGRAM } from '../../utils/browser';
import { PhaseOne } from './PhaseOne';
import { PhaseTwo } from './PhaseTwo';

export const LocationUnavailableModal = (props: {
  isOpen: boolean;
  close: () => void;
}) => {
  const [phase, setPhase] = useState(1);

  return (
    <IonModal
      isOpen={props.isOpen}
      animated={!IS_INSTAGRAM}
      backdropDismiss={false}
      keepContentsMounted
      handle={false}
      onWillDismiss={props.close}
      className={clsx(s.IonModal)}
    >
      {phase === 1 ? (
        <PhaseOne close={props.close} nextPhase={() => setPhase(2)} />
      ) : (
        <PhaseTwo close={props.close} />
      )}
    </IonModal>
  );
};
