import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import s from './SearchForm.module.scss';
import { IonCol, IonGrid, IonRow, IonSearchbar } from '@ionic/react';
import { IS_SAFARI_MOBILE } from '../../utils/browser';
import Button from '../Buttons/Button/Button';
import { SearchArgsType } from '../../redux/_types';
import { SearchTypeEnum } from '../SearchBlock/interface';
import Centralizer from '../Centralizer/Centralizer';
import { isPlatform } from '@ionic/core';

type Props = {
  setSearchArgs: React.Dispatch<React.SetStateAction<SearchArgsType>>;
  searchTerm: string;
  fixUiOnSearch: () => Promise<void>;
};

const SearchString: React.FC<Props> = ({
  setSearchArgs,
  searchTerm,
  fixUiOnSearch,
}) => {
  const [searchString, setSearchString] = useState<string>(searchTerm);

  const searchbarRef = useRef<HTMLIonSearchbarElement>(null);

  useEffect(() => {
    if (isPlatform('desktop')) {
      setTimeout(() => searchbarRef.current?.setFocus(), 0);
    }
  }, []);

  const executeSearch = useCallback(async () => {
    await fixUiOnSearch();
    setSearchArgs(args => ({
      ...args,
      searchTerm: searchString,
      type: SearchTypeEnum.BY_KEYWORDS,
    }));
  }, [fixUiOnSearch, searchString, setSearchArgs]);

  return (
    <IonGrid>
      <IonRow style={{}}>
        <IonCol style={{ margin: 0, padding: 0 }}>
          <IonSearchbar
            showClearButton={isPlatform('mobile') ? 'focus' : undefined}
            ref={searchbarRef}
            placeholder=""
            value={searchString}
            mode="ios"
            className={clsx(s.IonSearchbar)}
            enterkeyhint="go"
            onKeyDown={e => {
              if (e.keyCode === 13) {
                executeSearch();

                /* keyboard close */
                if (IS_SAFARI_MOBILE) {
                  let activeElement = document.activeElement as HTMLElement;
                  activeElement && activeElement.blur && activeElement.blur();
                }
              }
            }}
            onIonClear={() => {
              /* keyboard open */
              searchbarRef.current?.setFocus();
            }}
            onIonInput={e => setSearchString(e.detail.value || '')}
          />
        </IonCol>

        <IonCol size="auto" style={{ margin: 0, padding: 0 }}>
          <Centralizer enableVerticalCentralization>
            <Button
              skipCentralizer
              text="Search"
              color="secondary"
              className={clsx(s.Button__Search)}
              fill="solid"
              onClick={executeSearch}
            />
          </Centralizer>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default React.memo(SearchString);
