import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router';
import UserSettingsPage from './UserSettingsPage';
import UserBusinessRegistrationPage from './UserBusinessRegistrationPage';
import { animationSlide } from '../../../utils/animation';
import SupportPage from '../../SupportPage';
import FaqPage from '../../BusinessFaqPage';
import About from '../../About';
import { UserAccountSettingsPage } from './UserAccountSettingsPage';

const UserSettingsRoutes: React.FC = () => {
  return (
    <IonRouterOutlet ionPage animation={animationSlide}>
      <Route exact path="/settings/register-business">
        <UserBusinessRegistrationPage />
      </Route>

      <Route exact path="/settings/about">
        <About />
      </Route>

      <Route exact path="/settings/account">
        <UserAccountSettingsPage />
      </Route>

      <Route exact path="/settings/support">
        <SupportPage />
      </Route>

      <Route exact path="/settings/faq">
        <FaqPage />
      </Route>

      <Route exact path="/settings">
        <UserSettingsPage />
      </Route>
    </IonRouterOutlet>
  );
};

export default React.memo(UserSettingsRoutes);
