import React from 'react';
import s from './Reviews.module.scss';
import { AnchorEnum, buildAnchorId } from '../HeaderMobile/config';
import { useLandingPageContext } from '../../../context';
import { getStorageUrl } from '../../../Utils/getStorageUrl';
import { ActionButton } from '../../ActionButton/ActionButton';
import Centralizer from '../../../../../components/Centralizer/Centralizer';

type slide = {
  category: string;
  subcategory: string;
  src: string;
  desc: {
    text: string;
    author: string;
    date: string;
    authorPic: string;
  };
};

const slides: slide[] = [
  {
    category: 'Wedding',
    subcategory: 'Bali, Uluwatu',
    src: getStorageUrl('landing/reviews/1_wedding.webp'),
    desc: {
      text: 'It was perfect day for me as a wife, but Eventmaker team made it even better! They connected us with event managers and suggest venue options which turned out to 100% match with our vision... Our families and friends were excited about the wedding celebration and how beatiful and professionally organized it was. Venue was beautifully decorated and staff were polite and friendly. Thank you for make this day very special and unforgettable for us!',
      author: 'Luiza Alviar',
      date: 'June 2024',
      authorPic: getStorageUrl('landing/avatars/1_luiza.webp'),
    },
  },
  {
    category: 'Commercial festival',
    subcategory: 'Bali, Ubud',
    src: getStorageUrl('landing/reviews/2_festival.webp'),
    desc: {
      text: 'We doing international festivals and we contacted Eventmaker to find sound and lighting stage setup. With the help of thier team we find out the best possible equipment setup on the island, so our festival goes to another level. Guests were really surprised and said it was magic ✨',
      author: 'Nullah Oladoyinbo',
      date: 'December 2023',
      authorPic: getStorageUrl('landing/avatars/2_nullah.webp'),
    },
  },

  {
    category: 'Birthday party',
    subcategory: 'Bali, Canggu',
    src: getStorageUrl('landing/reviews/3_birthday.webp'),
    desc: {
      text: 'My girlfriend had a birthday and I was wondering how to make it rememberable and I find out Eventmaker. They organized really good sound, villa, dj, catering and alcohol bar. Very recommend.',
      author: 'Evgenii Delyanin',
      date: 'May 2024',
      authorPic: getStorageUrl('landing/avatars/3_evgenii.webp'),
    },
  },

  {
    category: 'Corporate event',
    subcategory: 'Bali, Ubud',
    src: getStorageUrl('landing/reviews/4_corporate.webp'),
    desc: {
      text: 'I never see this level of service in Bali before!!! I organized team building event for our company in Bali. It was really simple with this service, guys lead all the event management process, so I used to only confirm options they suggest. Special thanks to musicians for their rockstar show 🤘',
      author: 'Ratna Wirata',
      date: 'July 2024',
      authorPic: getStorageUrl('landing/avatars/4_ratna.webp'),
    },
  },
  {
    category: 'Private festival',
    subcategory: 'Bali, Canggu',
    src: getStorageUrl('landing/reviews/5_private.webp'),
    desc: {
      text: 'After I got an idea to celebrate my 35’s birthday in Bali really great, I started to research options. This guys were awesome! They asked me about all my preferences and after that suggest a lot of venues and entertainments available in the whole Bali. Result was perfect, it was really best celebration in my life!',
      author: 'Dmitry Ugaharov',
      date: 'July 2024',
      authorPic: getStorageUrl('landing/avatars/5_dmitry.webp'),
    },
  },
  {
    category: 'Project Presentation',
    subcategory: 'Bali',
    src: getStorageUrl('landing/reviews/6_presentation.webp'),
    desc: {
      text: 'Our company contacted Eventmaker to plan presentation event for one of ours project. Collaboration was simple and always in time. Definitely will be working with them again.',
      author: 'Anonym Client',
      date: 'November 2023',
      authorPic: getStorageUrl('landing/avatars/6_anonym.jpg'),
    },
  },
];

export const Reviews = () => {
  const { source } = useLandingPageContext();
  if (source !== 'customer') return null;

  return (
    <section className={s.section} id={buildAnchorId(AnchorEnum.REVIEWS)}>
      <p data-animation className={s.textTop}>
        Reviews
      </p>

      <div data-animation className={s.slider}>
        {slides.map((slide, index) => (
          <div key={index} className={s.slide}>
            <span className={s.slide__categoty}>
              {slide.category}
              {slide.subcategory && (
                <p className={s.slide__subcategory}>{slide.subcategory}</p>
              )}
            </span>

            <img src={slide.src} className={s.slidePic} alt={slide.src} />

            <div className={s.slide__desc}>
              <span className={s.slide__text}>{slide.desc.text}</span>
              <div className={s.slide__author}>
                <img
                  className={s.authorPic}
                  src={slide.desc.authorPic}
                  alt=""
                />

                <div>
                  <p className={s.autor}>{slide.desc.author}</p>
                  <p className={s.date}>{slide.desc.date}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <a
        data-animation
        className={s.textBtm}
        href="https://instagram.com/eventmaker.app"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Centralizer>
          {/* View more (some text here) */}
          <ActionButton arrow instagram text="View more" skipDefaultAction />
        </Centralizer>
      </a>
    </section>
  );
};
