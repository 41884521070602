import React, { useCallback, useMemo, useState } from 'react';
import BusinessDataForm, {
  BusinessFormValues,
} from '../../../components/BusinessForm/BusinessForm';
import { v4 as uuidv4 } from 'uuid';
import Page from '../../../components/Page/Page';
import { useTypedDispatch } from '../../../redux/hooks';
import { subjectsActionCreators } from '../../../redux/slices/subjects/actionCreators';
import { StorageDirectoryData, StorageFolderEnum } from '../../../@shared/file';
import { BusinessRegisteredModal } from '../../../components/BusinessRegisteredModal/BusinessRegisteredModal';

const UserBusinessRegistrationPage: React.FC = () => {
  const dispatch = useTypedDispatch();

  const storageDirectoryData: StorageDirectoryData = useMemo(() => {
    return {
      storageFolder: StorageFolderEnum.BUSINESS_AVATAR,
      businessId: uuidv4(),
    };
  }, []);

  const submitCallback = useCallback(
    async (values: BusinessFormValues) => {
      const body = {
        id: storageDirectoryData.businessId!,
        name: values.name,
        city: values.city?.id || null,
        city_custom: values.cityCustom,
        category: values.category?.id || null,
        category_custom: values.categoryCustom,
        phone_number: values.phoneNumber,
        uploaded_files: values.uploadedFiles.map(x => x.id),
        details: values.details,
      };

      await dispatch(subjectsActionCreators.createBusiness({ body }));
      setNewlyRegisteredBusiness({ id: body.id, name: body.name });
    },
    [dispatch, storageDirectoryData.businessId]
  );

  const [newlyRegisteredBusiness, setNewlyRegisteredBusiness] = useState<{
    id: string;
    name: string;
  }>();

  return (
    <Page disableDefaultIonContent headerProps={{ title: 'Register Business' }}>
      <BusinessRegisteredModal
        close={() => setNewlyRegisteredBusiness(undefined)}
        businessData={newlyRegisteredBusiness}
      />

      <BusinessDataForm
        action="add"
        initialFormValues={{
          name: '',
          category: {} as any,
          categoryCustom: null,
          city: {} as any,
          cityCustom: null,
          uploadedFiles: [],
          phoneNumber: '',
        }}
        submitCallback={submitCallback}
        storageDirectoryData={storageDirectoryData}
      />
    </Page>
  );
};

export default UserBusinessRegistrationPage;
